import { computed, ref } from 'vue';
import { useServices } from './services';

export function useProfile() {

  const { profilesService } = useServices();

  const profile = profilesService.profile;

  const isEarlyAdopter = computed(() => {
    return (profile.value?.roles || []).includes("earlyadopter")
  });

  const isAdmin = computed(() => {
    return (profile.value?.roles || []).includes("admin")
  });

  const emailVerified = computed(() => {
    return (!!profile.value?.emailVerified)
  });

  const hasSubscription = computed(() => {
    return (!!profile.value?.subscriptions && profile.value?.subscriptions.length > 0)
  });

  const showDynamicRoadtripMaps = computed(() => {
    if (!hasSubscription.value) return false; // No subscription, no choice
    if (!profile.value) return false; // No profile, no choice
    return !!profile.value.showDynamicRoadtripMaps;
  });

  const enabledFeatures = computed(() => {
    return profile.value?.enabledFeatures || []; 
  });

  return { 
    profile,
    isEarlyAdopter,
    isAdmin,
    emailVerified,
    hasSubscription,
    showDynamicRoadtripMaps,
    enabledFeatures
  }
};
